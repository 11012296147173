//packages
import { useNavigate } from 'react-router-dom'

//custom components
import DefaultAvatar from 'assets/images/default-avatar.png'

export default function ({ job }) {
  // console.log(job)
  const navigate = useNavigate()
  const onViewJob = () => {
    navigate('/auth/register')
  }
  return (
    // <div className="md:w-125 shadow-[4px_4px_16px_rgba(49,89,211,0.06)] rounded-3xl p-8">
    //   <div className="flex space-x-4">
    //     <div className="w-12 h-12 flex-none shadow-[0px_4px_8px_rgba(225,227,242,0.32)]">
    //       <img
    //         className="w-full rounded-lg object-cover"
    //         src={job.createdBy.logo ? job.createdBy.logo : DefaultAvatar}
    //       />
    //     </div>
    //     <div>
    //       <div className="flex space-x-3 items-center">
    //         <p className="text-blue-100 text-xs font-medium leading-6">
    //           {job.createdBy.name}
    //         </p>
    //         <div className="w-[2px] h-[2px] bg-gray-100" />
    //         <p className="text-green-100 text-uppercase leading-4 font-semibold text-xs capitalize">
    //           {/* {job.pay_preference} */}
    //         </p>
    //         <div className="w-[2px] h-[2px] bg-gray-100 hidden md:block" />
    //         <p className="text-gray-100 text-xs leading-4 font-medium hidden md:block">
    //           Full Time
    //         </p>
    //         <div className="w-[2px] h-[2px] bg-gray-100 hidden md:block" />
    //         <p className="text-gray-100 text-xs leading-4 font-medium hidden md:block">
    //           {job.duration} Mths
    //         </p>
    //         <div className="w-[2px] h-[2px] bg-gray-100 hidden md:block" />
    //         <p className="text-gray-100 text-xs leading-4 font-medium hidden md:block">
    //           {job.language}
    //         </p>
    //       </div>

    //       <p className="text-black-100 font-medium text-base leading-6">
    //         {job.title}
    //       </p>
    //     </div>
    //   </div>
    //   <div className="flex md:hidden items-center space-x-[10px] my-[20px]">
    //     <p className="text-green-100 text-uppercase leading-4 font-semibold text-xs capitalize">
    //       {/* {job.pay_preference} */}
    //     </p>
    //     <div className="w-[2px] h-[2px] bg-gray-100" />
    //     <p className="text-gray-100 text-xs leading-4 font-medium">Full Time</p>
    //     <div className="w-[2px] h-[2px] bg-gray-100 hidden" />
    //     <p className="text-gray-100 text-xs leading-4 font-medium">
    //       {job.duration} Mths
    //     </p>
    //     <div className="w-[2px] h-[2px] bg-gray-100" />
    //     <p className="text-gray-100 text-xs leading-4 font-medium">
    //       {job.language}
    //     </p>
    //   </div>
    //   <div className="flex flex-wrap max-h-[112px] overflow-hidden">
    //     {job.skills.slice(0, 4).map((skill) => (
    //       <div className="mr-4 rounded-lg skill-tag text-xs leading-5 text-purple-100 px-3 py-2.5 mt-4">
    //         {skill}
    //       </div>
    //     ))}
    //     {job.skills.length > 4 && (
    //       <div className="mr-4 rounded-lg skill-tag text-xs leading-5 text-purple-100 px-3 py-2.5 mt-4">
    //         +{job.skills.length - 4}
    //       </div>
    //     )}
    //   </div>
    //   <div className="text-[rgba(17,20,45,0.72)] text-sm leading-6 h-[84px] mt-6 max-h-[96px] line-clamp-4">
    //     {job.description}
    //   </div>
    //   <div className="mt-4">
    //     <button
    //       onClick={onViewJob}
    //       className="text-blue-100 text-sm leading-5 font-semibold py-3 px-6 border-[1.5px] border-[rgba(83,180,218,0.7)] rounded-xl btn-hover"
    //     >
    //       View Job
    //     </button>
    //   </div>
    // </div>
    <div className="md:w-125 shadow-[4px_4px_16px_rgba(49,89,211,0.06)] rounded-3xl p-8">
      <div className="flex space-x-4">
        <div className="w-12 h-12 flex-none shadow-[0px_4px_8px_rgba(225,227,242,0.32)]">
          <img
            className="w-full rounded-lg object-cover"
            src={job.avatar ? job.avatar : DefaultAvatar}
          />
        </div>
        <div>
          <div className="flex space-x-1 items-center">
            <p className="text-blue-100 text-[12px] font-medium leading-6">
              {job.company_name}
            </p>
            <div className="w-[2px] h-[2px] bg-gray-100" />
            <p className="text-green-100 text-uppercase leading-4 font-semibold text-[11px] uppercase">
              Hourly
            </p>
            <div className="w-[2px] h-[2px] bg-gray-100 hidden md:block" />
            <p className="text-gray-100 text-[11px] leading-4 font-medium hidden md:block">
              {job.duration}
            </p>
            <div className="w-[2px] h-[2px] bg-gray-100 hidden md:block" />
            <p className="text-gray-100 text-[11px] leading-4 font-medium hidden md:block">
              3 Mths
            </p>
            <div className="w-[2px] h-[2px] bg-gray-100 hidden md:block" />
            <p className="text-gray-100 text-[11px] leading-4 font-medium hidden md:block">
              {job.location}
            </p>
          </div>

          <p className="text-black-100 font-medium text-base leading-6">
            {job.job_role}
          </p>
        </div>
      </div>
      <div className="flex flex-wrap max-h-[112px] overflow-hidden">
        {job.skills.map((skill) => (
          <div className="mr-4 rounded-lg skill-tag text-xs leading-5 text-purple-100 px-3 py-2.5 mt-4">
            {skill}
          </div>
        ))}
      </div>
      <div className="text-[rgba(17,20,45,0.72)] text-sm leading-6 md:h-[430px] mt-6 md:max-h-[430px]">
        <p className="font-semibold text-[14px] text-blue-100 mb-2">
          Qualifications:
        </p>
        {job.qualifications.map((qua) => (
          <p className="text-gray-100 text-[14px] mb-0 leading-7 inline-block ml-2">
            {qua}
          </p>
        ))}

        <p className="font-semibold text-[14px] text-blue-100 mb-2 mt-10">
          Requirements:
        </p>
        {job.requirements.map((req) => (
          <p className="text-gray-100 text-[14px] mb-0 leading-7 inline-block ml-2">
            {req}
          </p>
        ))}
      </div>
      <div className="mt-10 md:mt-4">
        <button
          onClick={onViewJob}
          className="text-blue-100 text-sm leading-5 font-semibold py-3 px-6 border-[1.5px] border-[rgba(83,180,218,0.7)] rounded-xl btn-hover"
        >
          View Job
        </button>
      </div>
    </div>
  )
}
