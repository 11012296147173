import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

//custom components
import AcceptInvitationModal from './components/AcceptInvitationModal'
import DetailPanel from './components/DetailPanel'
import RejectInvitationConfirmModal from './components/RejectInvitationConfirmModal'
import UnRejectInvitationConfirmModal from './components/UnRejectInvitationConfirmModal'
import Sidebar from './components/Sidebar'
import WithdrawConfirmModal from './components/WithdrawConfirmModal'

//redux
import {
  approveInvite,
  fetchApplicantsList,
  fetchInvitesList,
  rejectInvite,
  unRejectInvite,
  resetAllStatus,
  withdrawApplication,
} from 'app/store/applicantsSlice'
import { getUser } from 'app/store/profileSlice'
import { updateUserLastActivity } from 'app/services/auth.service'
import { Helmet } from 'react-helmet'

export default function YourApplications() {
  const myDivRef = useRef(null)
  const [openWithdrawModal, setOpenWithdrawModal] = useState(false)
  const [handleAcceptLoading, setHandleAcceptLoading] = useState(false)
  const [openRejectInvitationModal, setOpenRejectInvitationModal] =
    useState(false)
  const [openUnRejectInvitationModal, setOpenUnRejectInvitationModal] =
    useState(false)
  const [openAcceptInviationModal, setOpenAcceptInvitationModal] =
    useState(false)
  const [fileUrl, setFileUrl] = useState('') //resume url
  const [resumeUploading, setResumeUploading] = useState(false)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const selectedApplicant = useSelector(
    (state) => state.applicants.selectedApplicant
  )

  const scrollToDiv = (div) => {
    myDivRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const fetchApplications = useCallback(() => {
    if (user) {
      const params = {
        limit: 100,
        offset: 0,
        userId: user?.id,
      }
      dispatch(fetchApplicantsList(params))
      dispatch(fetchInvitesList(params))
    }
  }, [dispatch, user])

  useEffect(async () => {
    //Update user last activity
    if (user) {
      await updateUserLastActivity(user.id)
    }
  }, [])

  useEffect(() => {
    fetchApplications()
    return () => {
      dispatch(resetAllStatus())
    }
  }, [fetchApplications])

  const handleWithdrawModal = useCallback(() => {
    setOpenWithdrawModal(true)
  }, [setOpenWithdrawModal])

  const handleRejectModal = useCallback(() => {
    setOpenRejectInvitationModal(true)
  }, [setOpenRejectInvitationModal])

  const handleUnRejectModal = useCallback(() => {
    setOpenUnRejectInvitationModal(true)
  }, [setOpenUnRejectInvitationModal])

  const handleAcceptModal = useCallback(() => {
    setOpenAcceptInvitationModal(true)
  }, [setOpenAcceptInvitationModal])

  const handleWithdraw = useCallback(async () => {
    setLoading(true)
    try {
      setOpenWithdrawModal(false)
      const resultAction = await dispatch(
        withdrawApplication({ id: selectedApplicant.id })
      )
      if (withdrawApplication.fulfilled.match(resultAction)) {
        toast.success('Withdraw Application Successfully')
      } else {
        if (resultAction.payload) {
          toast.error('Withdraw failed: ' + resultAction.payload.errorMessage)
        } else {
          toast.error('Withdraw applicant failed' + resultAction.error.message)
        }
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }, [selectedApplicant])

  const handleReject = useCallback(async () => {
    setOpenRejectInvitationModal(false)
    const params = {
      inviteId: selectedApplicant.id,
    }
    const resultAction = await dispatch(rejectInvite(params))
    if (rejectInvite.fulfilled.match(resultAction)) {
      toast.success('Denied Invite successfully')
    } else {
      toast.error('Denied Invite failed')
    }
  }, [setOpenRejectInvitationModal, selectedApplicant])

  const handleUnReject = useCallback(async () => {
    setOpenUnRejectInvitationModal(false)
    const params = {
      inviteId: selectedApplicant.id,
    }
    const resultAction = await dispatch(unRejectInvite(params))

    if (unRejectInvite.fulfilled.match(resultAction)) {
      toast.success('Un Denied Invite Successfully')
    } else {
      toast.error('Un Denied Invite Failed')
    }
  }, [setOpenUnRejectInvitationModal, selectedApplicant])

  const handleAccept = useCallback(async () => {
    setOpenAcceptInvitationModal(false)
    const params = {
      inviteId: selectedApplicant.id,
      resumeUrl: fileUrl,
    }
    const resultAction = await dispatch(approveInvite(params))
    if (approveInvite.fulfilled.match(resultAction)) {
      toast.success('Accepted Invite Successfully')
    } else {
      toast.error('Accepted Invite Failed')
    }
    fetchApplications()
    setHandleAcceptLoading(false)
  }, [setOpenAcceptInvitationModal, selectedApplicant])

  if (!user) return null

  return (
    <div className="min-h-screen px-[15px] md:px-16 pb-20 md:pb-0">
      <Helmet>
        <title>Your Job Applications - Remoterecruit.com</title>
        <meta
          name="description"
          content="Track your job applications and stay updated on your hiring status with Remote Recruit."
        />
      </Helmet>
      <div className="pt-[30px] pb-[20px] md:pt-12 md:pb-12 md:border-r relative border-r-gray-200 border-solid border-y-0 border-l-0 pr-11">
        <div className="text-black-100">
          <h5 className="leading-[21px] md:leading-8 font-medium text-[14px] md:text-2xl">
            Welcome, {user.name}!
          </h5>
          <h2 className="leading-[36px] md:leading-18 font-semibold text-[24px] md:text-5xl">
            Your Applications & Invitations
          </h2>
        </div>
      </div>
      <div className="md:flex">
        <div className="basis-[350px]">
          <Sidebar scrollToDiv={scrollToDiv} />
        </div>
        <div className="flex-1 md:px-7 mt-[20px] md:mt-0">
          <div ref={myDivRef}></div>
          {selectedApplicant && (
            <DetailPanel
              selectedApplicant={selectedApplicant}
              job={selectedApplicant.job}
              creator={selectedApplicant.creator}
              handleWithdrawModal={handleWithdrawModal}
              handleRejectModal={handleRejectModal}
              handleAcceptModal={handleAcceptModal}
              handleUnRejectModal={handleUnRejectModal}
              status={selectedApplicant.status}
              waitlisted={selectedApplicant.waitlisted}
              type={selectedApplicant.type}
              loading={loading}
            />
          )}
        </div>
      </div>
      <WithdrawConfirmModal
        isOpen={openWithdrawModal}
        closeModal={() => setOpenWithdrawModal(false)}
        handleWithdraw={handleWithdraw}
      />
      <RejectInvitationConfirmModal
        isOpen={openRejectInvitationModal}
        closeModal={() => setOpenRejectInvitationModal(false)}
        handleReject={handleReject}
      />
      <UnRejectInvitationConfirmModal
        isOpen={openUnRejectInvitationModal}
        closeModal={() => setOpenUnRejectInvitationModal(false)}
        handleReject={handleUnReject}
      />
      <AcceptInvitationModal
        resumeUploading={resumeUploading}
        setResumeUploading={setResumeUploading}
        isOpen={openAcceptInviationModal}
        closeModal={() => {
          setResumeUploading(false)
          setOpenAcceptInvitationModal(false)
        }}
        handleAccept={handleAccept}
        setFileUrl={setFileUrl}
        handleAcceptLoading={handleAcceptLoading}
      />
    </div>
  )
}
