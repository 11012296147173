import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import clsx from 'clsx'
import moment from 'moment'
import ShareOnSocial from 'react-share-on-social'

//custom components
import { setSelectedJob } from 'app/store/jobSlice'
import Avatar from 'components/Avatar'
import Button from 'components/Button'
import { NextIcon } from 'components/Icons'
import { DoubleNextIcon } from 'components/Icons'
import UpgradingPlanModal from 'components/Dialog/UpgradingPlanModal'
import RLogo from 'assets/images/rr-logo.png'

//api & redux
import { getUser } from 'app/store/profileSlice'
import { updateUserLastActivity } from 'app/services/auth.service'

export default function JobCard({ data }) {
  const shareUrl =
    process.env.REACT_APP_LIVE_BASE_URL + '/jobseeker/jobs/' + data.id
  const [showGetPremiumModal, setShowGetPremiumModal] = useState(false)
  const [clickedShareJob, setClickedShareJob] = useState(false)
  const [clickedEditJob, setClickedEditJob] = useState(false)
  const [showFullJob, setShowFullJob] = useState(false)
  const userId = useSelector((state) => state.auth.userId)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const profile = useSelector(getUser)
  const isBasicUser = profile.plan === 'basic'

  useEffect(async () => {
    //Update user last activity
    if (userId) {
      await updateUserLastActivity(userId)
    }
  }, [clickedShareJob, clickedEditJob])

  const handleViewAllApplicants = () => {
    dispatch(setSelectedJob(data))
    navigate(`/employer/job-posts/applicants/${data.id}`)
  }

  const onEdit = () => {
    dispatch(setSelectedJob(data))
    setClickedEditJob(!clickedEditJob)
    navigate(`/employer/job-posts/${data.id}/edit`)
  }

  const handleGoFindCandidates = () => {
    navigate(`/employer/find-candidates`)
  }

  if (!data) return null

  let applicants = _.isArray(data.candidates) ? data.candidates : []
  let invitors = _.isArray(data.invites) ? data.invites : []
  const users = applicants.concat(invitors)
  const uniqueUsers = Array.from(new Set(users.map((user) => user.id)))
    .slice(0, 6)
    .map((userId) => users.find((user) => user.id === userId))

  // console.log(uniqueUsers)
  {
    /* PREMIUM start comment this out no premium user */
  }
  // const awaitingApproval =
  //   isBasicUser && moment().diff(moment(data.createdAt), 'seconds') < 86400
  {
    /* end comment this out no premium user */
  }

  const awaitingApproval = false

  return (
    <div
      className={clsx(
        'bg-white shadow-[14px_41px_50px_rgba(49,89,211,0.07)] rounded-3xl relative'
      )}
    >
      {/* PREMIUM start comment this out no premium user */}
      {/* {awaitingApproval && (
        <div className="w-full h-full absolute z-10 top-0 left-0 flex flex-col items-center justify-center">
          <h5 className="text-black text-2xl font-semibold font-inter text-center">
            Pending Approval
          </h5>
          <h6 className="text-[#1E3E85] text-lg text-center font-semibold">
            Takes up to 24 hrs
          </h6>
          <div className="flex justify-center mt-7">
            <Button
              onClick={() => setShowGetPremiumModal(true)}
              variant="primary"
              className="px-[30px] py-[13px]"
            >
              Upgrade for Instant Approval
            </Button>
          </div>
        </div>
      )} */}
      {/* end comment this out no premium user */}
      <div
        className={clsx(
          'md:flex px-[15px] py-[20px] md:px-6 md:py-6 md:space-x-7.5 space-y-[20px] md:space-y-0',
          awaitingApproval && 'blur-md'
        )}
      >
        <div className="bg-[rgba(225,227,242,0.2)] rounded-xl py-[20px] px-[15px] md:p-7.5 md:pb-5 flex-1">
          <div className="flex items-center space-x-[5px] md:space-x-[10px]">
            <div className="flex items-center space-x-[5px] md:space-x-[10px]">
              {data.pay_preference.map((v) => (
                <div className="flex items-center space-x-[5px] md:space-x-[10px]">
                  <span className="uppercase text-green-100 text-[11px] leading-[18px] font-bold">
                    {v}
                  </span>
                  <div className="w-[2px] h-[2px] bg-[#73747A]" />
                </div>
              ))}
            </div>
            <div className="text-gray-1200 leading-4 text-[11px] font-semibold">
              {data.duration}
            </div>
            <div className="w-[2px] h-[2px] bg-[#73747A]" />
            <div className="text-gray-1200 leading-4 text-[11px] font-semibold">
              {data.language}
            </div>
          </div>
          <div className="text-black-100 leading-5 text-base font-semibold mt-4">
            {data.title}
          </div>
          {showFullJob ? (
            <div className="text-gray-100 leading-6 text-sm mt-4">
              <div
                class="content"
                dangerouslySetInnerHTML={{
                  __html: data.description,
                }}
              />
              <span
                onClick={() => setShowFullJob(false)}
                className="font-bold text-blue-300 cursor-pointer"
              >
                Read Less
              </span>
            </div>
          ) : (
            <div className="text-gray-100 leading-6 text-sm mt-4">
              <div
                class="content"
                dangerouslySetInnerHTML={{
                  __html: data.description.substring(0, 200),
                }}
              />{' '}
              {data.description.length > 200 && (
                <span
                  onClick={() => setShowFullJob(true)}
                  className="font-bold text-blue-300 cursor-pointer"
                >
                  Read More
                </span>
              )}
            </div>
          )}
          <div className="mt-7 hidden md:flex space-x-3">
            <Button
              onClick={onEdit}
              variant="primary"
              className="w-full md:w-[180px] text-[14px] md:text-sm font-bold leading-5 text-center text-white py-[13px] px-[23px]"
            >
              Edit Job
            </Button>
            <ShareOnSocial
              link={shareUrl}
              linkFavicon={RLogo}
              shareTo={[
                'facebook',
                'twitter',
                'email',
                'whatsapp',
                'telegram',
                'linkedin',
              ]}
              textToShare="Check out this job post!"
              linkTitle={data.title}
              linkMetaDesc={data.duration}
              noReferer
            >
              <Button
                variant="secondary"
                className="w-full md:w-[180px] text-[14px] md:text-sm font-bold leading-5 text-center text-white py-[13px] px-[23px]"
                onClick={() => setClickedShareJob(!clickedShareJob)}
              >
                Share Job
              </Button>
            </ShareOnSocial>
          </div>
        </div>
        <div
          className={clsx(
            'flex flex-col md:min-w-[400px] py-2',
            users.length > 0 ? 'justify-between' : 'justify-center'
          )}
        >
          <div className="space-y-6">
            {uniqueUsers.length > 0 && (
              <div className="text-[#11142D] font-semibold text-base">
                {uniqueUsers.length}{' '}
                {uniqueUsers.length > 1 ? 'Applicants' : 'Applicant'}
              </div>
            )}
            <div className="grid grid-cols-2 gap-x-5 gap-y-6">
              {uniqueUsers.map((v, index) => (
                <div className="flex items-center space-x-2.5" key={index}>
                  <div className="flex-none">
                    <Avatar
                      image={v.avatar}
                      className="w-[50px] md:w-9 h-[50px] md:h-9 rounded-md"
                    />
                  </div>
                  <div className="space-y-1">
                    <div className="flex items-center space-x-2 justify-between md:justify-start">
                      <div className="font-semibold leading-4 text-sm text-black-100">
                        {v.name}
                      </div>
                      {/* <div className="hidden md:block border border-green-100 text-green-100 rounded-md px-3 text-[10px] leading-[18px] font-bold font-inter">
                      {v.num_experience} Years Exp.
                    </div> */}
                    </div>
                    <div className="block md:hidden text-green-100 rounded-md text-[10px] leading-[18px] font-bold font-inter">
                      {v.num_experience} Years Exp.
                    </div>
                    <div className="flex items-center space-x-[5px] md:space-x-2.5">
                      {v.type === 'invited' ? (
                        <div
                          className={clsx(
                            v.status === 'accepted'
                              ? 'bg-[#35BE35]'
                              : v.status === 'rejected'
                              ? 'bg-[#C83F3F]'
                              : 'bg-green-100',
                            'text-[10px] leading-[18px] font-bold font-inter uppercase text-white rounded-md px-3'
                          )}
                        >
                          Invite {v.status}
                        </div>
                      ) : (
                        <div
                          className={clsx(
                            v.status === 'accepted'
                              ? 'bg-[#35BE35]'
                              : v.status === 'rejected'
                              ? 'bg-[#C83F3F]'
                              : 'bg-green-100',
                            'text-[10px] leading-[18px] font-bold font-inter uppercase text-white rounded-md px-3'
                          )}
                        >
                          Applicant {v.status}
                        </div>
                      )}

                      {/* <div className="text-[#1E3E85] text-[11px] leading-[18px] font-semibold font-inter">
                      {v.job_title}
                    </div>
                    <div className="w-[2px] h-[2px] bg-[#73747A]" />
                    <div className="text-[#9A9BA3] text-[11px] leading-[18px] font-semibold">
                      {v.language}
                    </div>
                    <div className="w-[2px] h-[2px] bg-[#73747A]" />
                    <div className="text-[#9A9BA3] text-[11px] leading-[18px] font-semibold">
                      {v.country}
                    </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {users.length > 0 && (
            <div
              onClick={handleViewAllApplicants}
              className="text-sm leading-5 font-bold text-green-100 cursor-pointer hidden md:flex items-center"
            >
              View all invites and applicants
              <NextIcon className="text-[#52B4DA] ml-2" />
            </div>
          )}
          {users.length > 0 && (
            <div className="mt-[26px] block md:hidden">
              <Button
                onClick={handleViewAllApplicants}
                variant="secondary"
                className="w-full md:w-[180px] text-[14px] md:text-sm font-bold leading-5 text-center text-white py-[13px] px-[23px]"
              >
                View All Applicants
              </Button>
            </div>
          )}
          {users.length === 0 && (
            <div className="max-w-[279px] mx-auto flex flex-col space-y-8 justify-center">
              <div className="leading-6 text-[12px] md:text-lg font-inter text-[#808191] text-center">
                No applicants found for this job. Click the button below to find
                appropriate candidates.
              </div>
              <div className="space-y-6">
                <div className="flex justify-center">
                  <Button
                    onClick={handleGoFindCandidates}
                    variant="primary"
                    className="px-2 py-1 leading-5 text-xs w-[134px]"
                  >
                    Find Candidates
                  </Button>
                </div>
                <div onClick={handleViewAllApplicants}>
                  <div className="text-[#52B4DA] text-sm leading-5 font-bold flex items-center space-x-2 justify-center cursor-pointer">
                    View Invites{' '}
                    <DoubleNextIcon className="text-[#52B4DA] ml-2" />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="mt-[10px] block md:hidden">
            <Button
              onClick={onEdit}
              variant="primary"
              className="w-full md:w-[180px] text-[14px] md:text-sm font-bold leading-5 text-center text-white py-[13px] px-[23px]"
            >
              Edit Job
            </Button>
            <ShareOnSocial
              link={shareUrl}
              linkFavicon={RLogo}
              shareTo={[
                'facebook',
                'twitter',
                'email',
                'whatsapp',
                'telegram',
                'linkedin',
              ]}
              textToShare="Check out this job post!"
              linkTitle={data.title}
              linkMetaDesc={data.duration}
              noReferer
            >
              <Button
                variant="secondary"
                className="w-full mt-[10px] md:w-[180px] text-[14px] md:text-sm font-bold leading-5 text-center text-white py-[13px] px-[23px]"
              >
                Share Job
              </Button>
            </ShareOnSocial>
          </div>
        </div>
      </div>
      <UpgradingPlanModal
        isOpen={showGetPremiumModal}
        closeModal={() => setShowGetPremiumModal(false)}
      />
    </div>
  )
}
